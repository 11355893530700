import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { AppService } from "../../core/app.service";
import { Store, select } from "@ngrx/store";
import { Location } from "@angular/common";
import * as UserActions from "../../store/user.actions";
import { RouterExtService } from "../../core/urlService";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import * as fromUser from "../../store/user.selectors";
import { CartIconComponent } from "../../common-module/cartIcon/cartIcon.component";
import { DomSanitizer } from '@angular/platform-browser';
import { Options } from 'ng5-slider';
declare var require: any

declare let rangeslider:Function;
const icons = require('!raw-loader!../../../assets/img/milkbottle.svg');
const brownbottle2 = require('!raw-loader!../../../assets/img/thermometer.svg');
const brownbottle = require('!raw-loader!../../../assets/img/shots.svg');
declare var $: any;

@Component({
  selector: "app-item-details",
  templateUrl: "./item-details.component.html",
  styleUrls: ["./item-details.component.scss"],
})
export class ItemDetailsComponent implements OnInit {



  comboItem: any[];
  comboForm: FormGroup;

  //combo
  currentVariations: number = 0;
  currentShots: number = 0;

  quantity: number = 1;
  dynamicPrice: any;
  dynamicOfferPrice: any;
  variety:string = "";
  shot:string = "";
  note:string = "";
  modifierArray:Array<any> = [];
  varietySize:string = "Small";
  shotSize:string = "1 Shots";
  id;
  prev;
  bannerImg;
  levelImg2;
  levelImg;
  list: any;
  table: any;
  isAdd: boolean;
  isAdded: boolean;
  isTakeAway: boolean;
  isCallWaiter: boolean;
  isLoading: boolean = false;
  isLazyLoader: boolean = false;
  emptyModifier: boolean = false;
  popTitle: string;
  popSubtitle: string;
  value: string;
  checkOrder: any;
  string: any;
  type: any;
  customizedType: any;
  selectedItem: string = '1';
  modifierAdd: number = 0;
  shotAdd: number = 0;
  variationAdd: number = 0;

  shotvalues: number = 0; 
  variationsvalues: number = 0; 

  shotOptions: Options = { floor: 0, ceil:2, step: 1,  vertical: true  }; 
  variationsOptions: Options = { floor: 0, ceil:2, step: 1,  vertical: true  }; 

  @ViewChild(CartIconComponent, { static: false }) cartIcon: CartIconComponent;

  constructor(
    private appService: AppService,
    private activeRoute: ActivatedRoute,
    private routerService: RouterExtService,
    private router: Router,
    private location: Location,
    private store: Store<any>,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.prev = this.routerService.getPreviousUrl();
    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.note = '';
      this.GetListById(this.id);
     
    });

    this.bannerImg = this.sanitizer.bypassSecurityTrustHtml(icons['default']);
    this.levelImg = this.sanitizer.bypassSecurityTrustHtml(brownbottle['default']);
    this.levelImg2 = this.sanitizer.bypassSecurityTrustHtml(brownbottle2['default']);

    this.store.pipe(select(fromUser.getType)).subscribe((result) => {
      if (result.type !== "TAKEAWAY") {
        this.isTakeAway = false;
        this.string =
          "Please confirm the order in the cart page to start preparing the dish";
      } else {
        this.isTakeAway = true;
        this.string = "Pay to confirm the order";
      }
    });
  }

  ngOnInit() {
    this.table = localStorage.getItem("table");
    this.comboForm = this.fb.group({
        comboItem: this.fb.array([])
    });
    localStorage.setItem("item_id", this.id);
    
  }



  
  listClick(event, id, name) {
    console.log(id);
    var data = { id: id, name: name };
    // sessionStorage.setItem("category_data", JSON.stringify(data));
    // this.page = 1;
    this.list = [];
    this.selectedItem = id; // don't forget to update the model here
  }


  back() {
    this.location.back();
  }

  GetListById(id: any) {
    this.quantity = 1;
    this.isLazyLoader = true;
    this.appService.GetListById(id).subscribe((result) => {
      this.list = result.data;
      this.dynamicPrice = result.data.price;
      this.dynamicOfferPrice = result.data.offer_price;
     
      if(result.data.menu_type == 'single'){
        this.variationsOptions.ceil = result.data['variations']['content'].length - 1;
        this.shotOptions.ceil = result.data['shots']['content'].length - 1;
       
        result.data['shots']['content'].forEach(element => {
            if(element['is_default'] == true){
              this.changeStepRange('shots',element,0,result.data['shots']['content'].length)
            }
        });
        result.data['variations']['content'].forEach(element => {
          if(element['is_default'] == true){
            this.changeStepRange('variations',element,0,result.data['variations']['content'].length)
          }
          });
        result.data['modifiers']['content'].forEach(element => {
          if(element['is_default'] == true){
              this.onChangeModifier(element._id,true,result.data['modifiers']['content']['multiple'])
            }
          });
     
      }

      if(result.data.menu_type == 'combo'){
          this.comboForm.get("comboItem")['controls'] = [];
          var shot_id;
          var modifiers_id;
          result.data['combo'].forEach(element => {
            element['menu_id']['shots']['content'].forEach(shotsItem => {
              if(shotsItem['is_default'] == true){
                shot_id = shotsItem['_id']
              }
              });
            element['menu_id']['modifiers']['content'].forEach(modifiersItem => {
              if(modifiersItem['is_default'] == true){
                modifiers_id = modifiersItem['_id']
              }
              });
            (this.comboForm.get("comboItem") as FormArray).push(
              this.addComboFormGroup(element,shot_id,modifiers_id)
            );
          });
        }
        this.isLazyLoader = false;
      }, (error) => {
          this.isLazyLoader = false;
      }
    );
     
  }

  addComboFormGroup(combo: any,shot_id,modifiers_id): FormGroup {
    return this.fb.group({
      _id: combo['menu_id']._id,
      variety: combo.variety,
      shot: new FormControl(shot_id),
      modifier: new FormControl(modifiers_id)
    });
  }

  GetListOfferById(id: any) {
    this.appService.GetListOfferById(id).subscribe((result) => {
      this.list = result.data;
    });
  }

  changeStepRange(type,array,$event,total){
    switch (type) {
      case 'variations':
        this.variety = array,
        this.variationAdd = parseFloat(array.price);
        this.varietySize = array.item;
        this.getCartPrice();
        break;
      case 'shots':
        this.shot = array;
        this.shotAdd = parseFloat(array.price);
        this.shotSize = array.item;
        this.getCartPrice();
        break;
    }
    
    
  }

  //textauto increase height
  auto_grow(element) {
    element.target.style.height = "5px";
    element.target.style.height = (element.target.scrollHeight)+"px";
  }

  getCartPrice(){
    let item;
    if(this.list.menu_type == 'single'){
      item = [{
        _id: this.list._id,
        shot: this.shot['_id'],
        modifier: this.modifierArray,
        variety: this.variety['_id'],
      }]
    }else{
      item = this.comboForm.get('comboItem').value;
    }
   
    let data = {
      _id: this.list._id,
      items:item,
      quantity: this.quantity,
    };
    this.appService.getCartPrice(data).subscribe((result) => {
      this.dynamicPrice = result.data.price;
      this.dynamicOfferPrice = result.data.offer_price;
     console.log(result);
    });
  }

  onChangeModifier(modifier:string, isChecked: boolean,type){
    if(type == true){
        if(isChecked) {
          this.modifierArray.push(modifier);
        } else {
          let index = this.modifierArray.indexOf(modifier);
          this.modifierArray.splice(index,1);
        }
    }else{
      this.modifierArray =[]
      this.modifierArray.push(modifier);
    }
    this.getCartPrice();
  }

  checkValid(){
    if((this.list['enable_modifiers']  == true && this.modifierArray.length != 0 ? true : this.list['enable_modifiers']  == false ? true : false ) && 
      (this.list['enable_shots'] == true && this.shot['_id'] != undefined ? true : this.list['enable_shots']  == false ? true : false) &&
      (this.list['enable_variations'] == true && this.variety['_id'] != undefined ? true : this.list['enable_variations']  == false ? true : false)
      ){
       return true
      }else{
       return false
      }
  }
  


  addToCart(type) {
    if(this.checkValid()){
      let item;
      if(this.list.menu_type == 'single'){
        item = [{
          _id: this.list._id,
          shot: this.shot['_id'],
          modifier: this.modifierArray,
          variety: this.variety['_id'],
        }]
      }else{
        item = this.comboForm.get('comboItem').value;
      }
  
      let data = {
        _id: this.list._id,
        items:item,
        quantity: this.quantity,
        notes:this.note
      };
      console.log(data)
      this.isLoading = true;
      this.appService.addToCart(data).subscribe((result) => {
        this.store.dispatch(new UserActions.AddUsersSuccess({ data: result }));
  
        if (type == "button") {
          this.popTitle = "Item Added to Cart";
          this.popSubtitle = this.string;
          this.value = "normal";
          this.isAdd = true;
          this.isAdded = true;
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
      );
    }else{
      this.emptyModifier = true;
      this.isAdd = true;
    }

  }

  onIncrement(type): void {
    this.quantity += 1;
    this.addToCart(type);
  }

  onDecrement(type): void {
    this.quantity -= 1;

    if (this.quantity >= 1) {
        this.addToCart(type);
    }else{
      this.quantity = 1;
    }
   
  }

  paynow() {
    let data = {
      table: localStorage.getItem("table"),
    };
    let order_id = localStorage.getItem("order_id");

    if (localStorage.getItem("order_id")) {
      data["order_id"] = localStorage.getItem("order_id"); // update order
      this.appService.updateOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);
          this.router.navigate(["/invoice"]);
        },
        (error) => {
          alert(error);
        }
      );
    } else {
      this.appService.createOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);
          this.router.navigate(["/invoice"]);
        },
        (error) => {
          alert(error);
        }
      );
    }
  }
  confirmOrder() {
    let data = {
      table: localStorage.getItem("table"),
    };
    let order_id = localStorage.getItem("order_id");

    if (localStorage.getItem("order_id")) {
      data["order_id"] = localStorage.getItem("order_id"); // update order
      this.appService.updateOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);

          this.popTitle = "Your Order is Confirmed";
          this.popSubtitle =
            "Your order will be taking 20-30 mins to be prepared and served";
          this.value = "confirm";
          this.isAdded = true;
        },
        (error) => {
          alert(error);
        }
      );
    } else {
      this.appService.createOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);
          this.popTitle = "Your Order is Confirmed";
          this.popSubtitle =
            "Your order will be taking 20-30 mins to be prepared and served";
          this.value = "confirm";
          this.isAdded = true;
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  clickAction(value) {
    this.isCallWaiter = false;
    if (value == "yes") {
      this.appService.callWaiter().subscribe(
        (result) => {
          // this.router.navigate(["/call-waiter"]);
          console.log("api call");
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  callWaiter() {
    this.isCallWaiter = true;
  }

  proceedToCart(value) {
    this.isAdded = false;
   this.isAdd = false;
    this.cartIcon.clickMe(); // call cart button
    $("#meetDesigner").modal();
  }

  listView(id) {
    localStorage.setItem("item_id", this.id);
    this.router.navigate(["/item-details/", id]);
    // this.GetListById(id);
    this.isAdd = false;
  }
}
