import { Component, OnInit,AfterViewInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "../../core/app.service";
import { merge, Subject, Observable, of as observableOf } from "rxjs";
import { Store, select } from "@ngrx/store";
import { SharedService } from "../../core/sharedData";
import { AuthService } from "../../core/auth/auth.service";
import { ViewportScroller } from "@angular/common";
import {Location} from '@angular/common'; 
import { DomSanitizer } from '@angular/platform-browser';
declare var $;

const brownbottle = require('!raw-loader!../../../assets/img/brownbottle.svg');

import {
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

@Component({
  selector: "app-home-detail",
  templateUrl: "./home-detail.component.html",
  styleUrls: ["./home-detail.component.scss"],
})
export class HomeDetailComponent implements OnInit,AfterViewInit {
  selectedItem: string;
  searchText: string;
  size: any;
  table: any;
  selectTitle: any;
  categories = [];
  list = [];
  offers = [];
  category_id: any;
  id: any;
  name: any;
  levelImg: any;
  isCardLoading:boolean = false;
  page: any = 1;
  total: any = 0;
  moreBoxes = [];

  public filterSubject = new Subject<string>();
  public config: SwiperConfigInterface = {};

  constructor(
    private appService: AppService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthService,
    private sharedService: SharedService,
    private store: Store<any>,
    private vps: ViewportScroller,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {
    this.levelImg = this.sanitizer.bypassSecurityTrustHtml(brownbottle['default']);
    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
    });
    var data = { id: this.id};
    sessionStorage.setItem("category_data", JSON.stringify(data));
  }

  ngAfterViewInit() {
    this.config = {
      keyboard: true,
      navigation: true,
      centeredSlides: true,
      spaceBetween: 15,
      slidesPerView: 1.1,
      loop: true,
      preloadImages: false,
      lazy: true,
      speed: 1000,
      effect: "slide",
      observer: true,
      observeParents: true,
      breakpoints: {
        480: {
          slidesPerView: 1.1,
          spaceBetween: 8,
        },
      },
    };
  }
  ngOnInit() {
    this.getCategories();
  }



  listClick(event, id) {
    console.log(id);
    var data = { id: id };
    sessionStorage.setItem("category_data", JSON.stringify(data));
    this.page = 1;
    this.list = [];
    this.moreBoxes = [];
    this.selectedItem = id; // don't forget to update the model here
    this.getList(id);
    this.id = id;

    this.location.replaceState("/home-detail/" + id);

    var self = this;
    setTimeout(function(){   
      var scrollTo = $('#' + self.id).position().left; 
      $('.scroller').animate({'scrollLeft': scrollTo}, 10); 
    }, 10);
  
  }

 

  getCategories() {
    this.appService.getCategories().subscribe(
      (result) => {
        this.categories = result.data;
        var obj = JSON.parse(sessionStorage.getItem("category_data"));
        if (obj) {
          this.id = obj.id;
          // this.name = obj.name;
        }

        this.listClick("default", this.id);
      },
      (error) => {
        console.log("error");
      }
    );
  }

  getSearch(query) {
    if (query.length > 0 && query != "") {
      $("#grouplist").hide();
      $("#swiper").hide();
      this.appService.getSearch(query).subscribe(
        (result) => {
          this.list = result.data.items;
          this.offers = result.data.offers;
          this.total = result.data.total;
        },
        (error) => {
          console.log("error");
        }
      );
    } else {
      this.getList(this.selectedItem);
      $("#grouplist").show();
      $("#swiper").show();
    }
  }

  getList(id) {
    this.category_id = id;
    this.isCardLoading = true;
    this.appService.getHomeList(this.category_id, this.page).subscribe(
      (result) => {
        console.log(result)
        this.list = result.data.items;
        this.offers = result.data.offers;
        this.total = result.data.total;
        this.isCardLoading = false;
      },
      (error) => {
        this.isCardLoading = false;
        console.log("error");
      }
    );
  }

  listView(id) {
    this.router.navigate(["/item-details/", id]);
  }

  listOfferView(id) {
    this.router.navigate(["/offer-details/", id]);
  }

  onScroll(e) {
    if (this.list.length >= this.total) {
      console.log("No more items");
      return;
    }
    this.page += 1;
    console.log("scrolled!!", this.list);
    this.appService.getHomeList(this.category_id, this.page).subscribe(
      (result) => {
        console.log(result);
        this.moreBoxes = result.data.items;
        this.list = [...this.list, ...this.moreBoxes];
      },
      (error) => {
        console.log("error");
      }
    );
    // console.log( this.moreBoxes);
  }
}

