

import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import {AppService} from '../../core/app.service';

/**
 * @title Basic table
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})


export class FeedbackComponent implements OnInit {

  feedBackForm: FormGroup;
  isLoading:boolean = false;
  constructor(
    public route: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,

  ) {}

  ngOnInit() {

    this.feedBackForm = this.formBuilder.group({
      order_id:'',
      rating: 4,
      comment: [''],
    });
  }

  get f() { return this.feedBackForm.controls; }


  
  
  onSubmit() {
      if (this.feedBackForm.invalid) {
          return;
      }
      this.feedBackForm.controls['order_id'].setValue(localStorage.getItem("order_id"));
      this.isLoading = true;
      this.appService.feedBack(this.feedBackForm.value).subscribe(
      result => {
        this.isLoading = false;
        this.route.navigate(['/thankyou']);
      },
      error => {
        this.isLoading = false;
        console.log('Please try again')
      }
    );  
    
  }
}














