import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CitGlobalContantsService {
  // localhost
  baseUrl = environment.baseUrl + "/";

  //new app start

  tokenUrl: string = this.baseUrl + "token";

  search: string = this.baseUrl + "search";

  homeList: string = this.baseUrl + "menus";

  offerList: string = this.baseUrl + "offers";

  categories: string = this.baseUrl + "categories";

  myCart: string = this.baseUrl + "cart";

  orders: string = this.baseUrl + "orders";

  feedBack: string = this.baseUrl + "feedbacks";

  callWaiter: string = this.baseUrl + "call-waiter";


  //new app end
}
