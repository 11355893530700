import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { AppService } from "../../core/app.service";
import { Store, select } from "@ngrx/store";
import { Location } from "@angular/common";
import * as UserActions from "../../store/user.actions";
import { RouterExtService } from "../../core/urlService";

import { SharedService } from "../../core/sharedData";
import * as fromUser from "../../store/user.selectors";
import { Iuser } from "../../store/users.model";
import { Observable } from "rxjs/Observable";

@Component({
  selector: "app-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
})
export class OfferDetailsComponent implements OnInit {
  quantity: number = 1;
  id;
  prev;
  list: any;
  table: any;
  isCallWaiter: boolean;
  isTakeAway: boolean;
  value: string;
  checkOrder: any;
  string: any;
  type: any;

  constructor(
    private appService: AppService,
    private activeRoute: ActivatedRoute,
    private routerService: RouterExtService,
    private router: Router,
    private location: Location,
    private store: Store<any>
  ) {
    this.prev = this.routerService.getPreviousUrl();
    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.GetListOfferById(this.id);
    });
  }

  ngOnInit() {
    this.table = localStorage.getItem("table");

    localStorage.setItem("item_id", this.id);
  }

  back() {
    this.location.back();
  }

  GetListOfferById(id: any) {
    this.appService.GetListOfferById(id).subscribe((result) => {
      this.list = result.data;
    });
  }

  clickAction(value) {
    this.isCallWaiter = false;
    if (value == "yes") {
      this.appService.callWaiter().subscribe(
        (result) => {
          // this.router.navigate(["/call-waiter"]);
          console.log("api call");
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  callWaiter() {
    this.isCallWaiter = true;
  }

  listView(id) {
    localStorage.setItem("item_id", this.id);
    this.router.navigate(["/item-details/", id]);
    this.GetListOfferById(id);
  }
}
