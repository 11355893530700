import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-call-waiter",
  templateUrl: "./call-waiter.component.html",
  styleUrls: ["./call-waiter.component.scss"],
})
export class CallWaiterComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {}

  back() {
    this.location.back();
  }
}
