import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClientHelperService } from "../core/Http/http-client-helper.service";
import { CitGlobalContantsService } from "../core/globalUrl/cit-global-contants.service";
import { Subject } from "rxjs/Subject";

@Injectable({
  providedIn: "root",
})
export class AppService {
  table: any;
  constructor(
    private httpClient: HttpClientHelperService,
    private globalConst: CitGlobalContantsService
  ) {}

  public stringSubject = new Subject<string>();

  getToken(table): Observable<any> {
    return this.httpClient.postRequest(
      this.globalConst.tokenUrl + "/" + table,
      {}
    );
  }

  ///start new project

  getSearch(query): Observable<any> {
    return this.httpClient.getRequest(
      this.globalConst.search + "?term=" + query,
      {}
    );
  }

  getCategories(): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.categories, {});
  }

  getHomeList(id, page): Observable<any> {
    return this.httpClient.getRequest(
      this.globalConst.homeList + "?category_id=" + id + "&page=" + page,
      {}
    );
  }

  GetListById(id): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.homeList + "/" + id, {});
  }

  GetType(menu_id): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.homeList + "/" + menu_id, {});
  }

 

  GetListOfferById(id): Observable<any> {
    return this.httpClient.getRequest(
      this.globalConst.offerList + "/" + id,
      {}
    );
  }

  GeMyCart(): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.myCart, {});
  }

  confirmPayment(id): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.orders + "/" + id, {});
  }



  getCartPrice(data): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.myCart + '/price' , data);
  }

  addToCart(data): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.myCart, data);
  }

  deleteCart(index): Observable<any> {
    [];
    return this.httpClient.deleteRequest(
      this.globalConst.myCart + "/" + index,
      {}
    );
  }

  createOrders(data): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.orders, data);
  }

  updateOrders(data): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.orders, data);
  }

  getAllItem(): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.orders, {});
  }

  feedBack(data): Observable<any> {
    return this.httpClient.postRequest(this.globalConst.feedBack, data);
  }

  checkOrder(): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.orders + "/table", {});
  }

  callWaiter(): Observable<any> {
    return this.httpClient.getRequest(this.globalConst.callWaiter, {});
  }

  ///end new project

  //Start Payment

  initiatePayment(data: any): Observable<any> {
    return this.httpClient.postRequest(
      this.globalConst.orders + "/payment",
      data
    );
  }

  verifyPayment(data: any): Observable<any> {
    return this.httpClient.postRequest(
      this.globalConst.orders + "/payment/verify",
      data
    );
  }

  //End Payment
}
