import { Component, OnInit } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { interval } from 'rxjs';
import {AppService} from '../../core/app.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  data:any;
  interval:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) { 

    this.activeRoute.queryParams.subscribe(params => {
      console.log(params);
      this.data = {
        AuthCode : params['AuthCode'],
        ECI : params['ECI'],
        PaymentId : params['PaymentId'],
        RRN : params['RRN'],
        ResponseCode : params['ResponseCode'],
        Result : params['Result'],
        SubscriptionId : params['SubscriptionId'],
        TrackId : params['TrackId'],
        TranId : params['TranId'],
        UserField1 : params['UserField1'],
        UserField3 : params['UserField3'],
        UserField4 : params['UserField4'],
        UserField5 : params['UserField5'],
        amount : params['amount'],
        cardBrand : params['cardBrand'],
        cardToken : params['cardToken'],
        email : params['email'],
        maskedPAN : params['maskedPAN'],
        payFor : params['payFor'],
        responseHash : params['responseHash'],
        order_id: params['TrackId']
      }
    })
     
    
  }



  ngOnInit() {
    
    this.confirmPayment();
  }


  confirmPayment() {
    this.appService.verifyPayment(this.data).subscribe(
      (result) => {
        console.log(result);
        localStorage.setItem("order_id",this.data['order_id']);
        this.router.navigate(["/payment-success"]);
      },
      (error) => {
        this.router.navigate(["/payment-failure"]);
      }
    );
   }

}
