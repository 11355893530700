import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { AppService } from "../../core/app.service";
@Component({
  selector: "app-served-order",
  templateUrl: "./served-order.component.html",
  styleUrls: ["./served-order.component.scss"],
})
export class ServedOrderComponent implements OnInit {
  isCallWaiter: boolean;
  constructor(private router: Router, private appService: AppService) {}

  ngOnInit() {}
  clickAction(value) {
    this.isCallWaiter = false;
    if (value == "yes") {
      this.appService.callWaiter().subscribe(
        (result) => {
          this.router.navigate(["/call-waiter"]);
          console.log("api call");
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  callWaiter() {
    this.isCallWaiter = true;
  }
}
