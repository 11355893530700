import { Component, OnInit } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-lazyLoader",
  templateUrl: "./lazyLoader.component.html",
  styleUrls: ["./lazyLoader.component.scss"],
})
export class LazyLoaderComponent implements OnInit {
  title = "scube";
  data: any;
  checkOrder: any;
  list: any;
  constructor(
  ) {
  }

  ngOnInit() {}
}
