import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { RouterExtService } from "../../core/urlService";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { AppService } from "../../core/app.service";
import { Store, select } from "@ngrx/store";

import * as UserActions from "../../store/user.actions";
import * as fromUser from "../../store/user.selectors";

import { WindowRefService } from "../../window-ref.service";

declare var RazorpayCheckout: any;

@Component({
  selector: "app-proceed-pay",
  templateUrl: "./proceed-pay.component.html",
  styleUrls: ["./proceed-pay.component.scss"],
})
export class ProceedPayComponent implements OnInit {
  order_id: any;
  address: any;
  paymentForm: FormGroup;
  prev;

  addressDetails: boolean = false;
  giftDetails: boolean = false;
  atleast: boolean = false;
  isLoading: boolean = false;
  submitted = false;
  mobNumberPattern = "^[0-9]{10}$";

  constructor(
    private activeRoute: ActivatedRoute,
    private routerService: RouterExtService,
    private winRef: WindowRefService,
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private appService: AppService,
    private router: Router
  ) {
    this.routerService.getPreviousUrl();
  }

  ngOnInit() {
    this.prev = this.routerService.getPreviousUrl();

    this.order_id = localStorage.getItem("order_id");
    this.paymentForm = this.formBuilder.group(
      {
        order_id: [this.order_id, [Validators.required]],
        name: ["", [Validators.required]],
        email: ["", [Validators.email]],
        phone: [""],
        payment_type: ["OFFLINE", [Validators.required]],
      },
      { validator: this.atLeastOne }
    );
  }

  get f() {
    return this.paymentForm.controls;
  }

  atLeastOne(group: FormGroup): { [s: string]: boolean } {
    if (group) {
      if (group.controls["email"].value || group.controls["phone"].value) {
        return null;
      }
    }
    return { error: true };
  }

  fileChangeEvent($event) {
    this.atleast = false;
  }
  onSubmit() {
    console.log(this.paymentForm.value)
    this.submitted = true;
    this.paymentForm.controls["order_id"].setValue(
      localStorage.getItem("order_id")
    );
    if (this.paymentForm.invalid) {
      this.atleast = true;
      return;
    }
    this.razorPay();
  }

  razorPay() {
    this.isLoading = true;
    this.appService.initiatePayment(this.paymentForm.value).subscribe(
      (result) => {
        this.store.dispatch(
          new UserActions.LoadCheckOrderSuccess({ data: result })
        );
        this.isLoading = false;
        if (result.data.payment_type == "ONLINE") {

          if(result.data.response_type == "url"){
               window.location.href = result.data.response;
          }else{
              this.payNow(result.data.response);
          }
          
        console.log(result.data)
        } else {

          this.router.navigate(["/waiting"]);
        }
      },
      (error) => {
        this.isLoading = false;
        this.router.navigate(["/payment-failure"]);
      }
    );
  }




  payNow(data) {
    var self = this;
    let jsonItem = JSON.parse(data);
    var options = jsonItem;

    jsonItem.handler = function (response) {
      let verifyData = response;
      verifyData.order_id = self.order_id;

      self.appService.verifyPayment(verifyData).subscribe(
        (result) => {
          self.router.navigate(["/payment-success"]);
        },
        (error) => {
          self.router.navigate(["/payment-failure"]);
        }
      );
    };

    var rzp1 = new this.winRef.nativeWindow.Razorpay(options);

    rzp1.open();
  }

  paid() {
    this.router.navigate(["/payment-failure"]);
  }
}
