import { Component,ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
} from "@angular/router";
import { NgwWowService } from "ngx-wow";
import { AppService } from "./core/app.service";
import { RouterExtService } from "./core/urlService";
import { SharedService } from "./core/sharedData";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as UserActions from "./store/user.actions";
import * as fromUser from "./store/user.selectors";
import { Iuser } from "./store/users.model";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit  {
  title = "scube";
  table: any;
  isShow: boolean;
  isLayer: boolean;
  isAdded: boolean;
  isRemove: boolean;
  isCallWaiter: boolean;
  isView: boolean;
  size: number;
  subtotal:number = 0;
  tax:number = 10;
  prev;
  payBtn: boolean = false;
  isLoading:boolean = false;
  isLoadingResult:boolean = true;
  isEdit = {};
  edit_id = '';
  quantity: number = 1;
  list = [];
  meta:any;
  users: Iuser[] = [];
  popTitle: string;
  popSubtitle: string;
  popBtn: string;
  value: string;
  index: any;
  viewDetail: any;
  public showOverlay = true;
  topPosToStartShowing = 100;
  clickEventsubscription: Subscription;
  //   @HostListener("window:scroll", ["$event"]);
  @ViewChild("closebutton", { static: false }) closebutton;
  constructor(
    private router: Router,
    private wowService: NgwWowService,
    private route: ActivatedRoute,
    private appService: AppService,
    private sharedService: SharedService,
    private store: Store<any>,
    private elRef:ElementRef
  ) {
    this.wowService.init();

    this.router.events.subscribe((event: any) => {
      window.scrollTo(0, 0);
      if (event instanceof NavigationEnd) {
        if (this.router.url == "/product") {
          $("body").addClass("");
        } else {
          $("body").removeClass("product-mobile");
        }

        if (this.router.url == "/home") {
          $("body").addClass("home");
        } else {
          $("body").removeClass("home");
        }
      }
    });

    this.clickEventsubscription = this.sharedService
      .getLoadTable()
      .subscribe(() => {
        // this.tableLoad();
      });

    this.clickEventsubscription = this.sharedService
      .getClickEvent()
      .subscribe(() => {
        this.incrementCount();
      });
  }

  ngAfterViewChecked(){
    $('#loader').hide();
  }

  ngOnInit() {}

  // tableLoad(){

  // }

  getSubtotal()  {
    this.subtotal =  this.list.reduce(
      (acc, {price}) => (acc += +(price || 0)),
      0)
    return this.subtotal > 0 ? this.subtotal : 0
}


  incrementCount() {
    let table = localStorage.getItem("table");
    if (table != null) {
      this.getList();
    }
  }

  checkScroll() {
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getList() {
    this.isLayer = false;
    this.appService.GeMyCart().subscribe(
      (result) => {
        console.log( result)
        this.list = result.data.items;
        this.meta = result.data.meta;
        let l = [];
        let k = this.list.find((x) => {
          if (x.order_confirmed == false) {
               l.push(x);
          }
        });
        console.log(l.length)
        if (l.length > 0) {
          this.payBtn = true;
        } else {
          this.payBtn = false;
        }
        this.isLoadingResult = false;
      },
      (error) => {
        this.isLoadingResult = false;
        console.log("error");
      }
    );
  }

  deleteItem(index) {
    this.edit_id = '';
    this.isLayer = true;
    this.popTitle = "Remove Item from Cart?";
    this.popSubtitle =
      "Are you sure you want to remove this item from your cart?";
      this.popBtn =
      "Remove";
    this.index = index;
    this.isRemove = true;
  }

  editItem(index,edit_id) {
    this.isLayer = true;
    this.popTitle = "Edit Item from Cart?";
    this.popSubtitle =
      "Are you sure you want to remove this item from your cart?";
    this.popBtn =
      "Edit";
    this.index = index;
    this.edit_id = edit_id;
    this.isRemove = true;
  }

  remove(index) {
    this.appService.deleteCart(index).subscribe(
      (result) => {
        this.list = result.data.items;
        this.store.dispatch(new UserActions.RemoveUsers({ data: result }));
        console.log(this.edit_id)
        if(this.edit_id != ''){
            $('#meetDesigner').modal('hide');
            this.router.navigate(["/item-details/", this.edit_id]);
        }
        this.isRemove = false;
        this.getList();
        this.isLayer = false;
      },
      (error) => {
        this.isLayer = false;
        console.log(error);
      }
    );
  }

  dontRemove() {
    this.edit_id = '';
    this.isRemove = false;
    this.isLayer = false;
  }

  addToCart(item) {
    let data = {
      _id: item._id,
      quantity: item.quantity,
    };
    this.appService.addToCart(data).subscribe((result) => {
      this.store.dispatch(new UserActions.AddUsersSuccess({ data: result }));
    });
  }

  viewDetails(details){
    this.isLayer = true;
    this.isView = true;
    this.viewDetail = details;
    console.log(details)
  }
  closeView(){
    this.isView = false;
    this.isLayer = false;
  
  }
  confirmOrder() {
    let data = {
      table: localStorage.getItem("table"),
    };
    let order_id = localStorage.getItem("order_id");

    if (localStorage.getItem("order_id")) {
      data["order_id"] = localStorage.getItem("order_id"); // update order
      this.isLoading = true;
      this.appService.updateOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);

          this.popTitle = "Your Order is Confirmed";
          this.popSubtitle =
            "Your order will be taking 20-30 mins to be prepared and served";
          this.value = "confirm";
          this.isAdded = true;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      this.appService.createOrders(data).subscribe(
        (result) => {
          this.store.dispatch(
            new UserActions.LoadCheckOrderSuccess({ data: result })
          );
          localStorage.setItem("order_id", result.data._id);
          this.popTitle = "Your Order is Confirmed";
          this.popSubtitle =
            "Your order will be taking 20-30 mins to be prepared and served";
          this.value = "confirm";
          this.isAdded = true;
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  ok(value) {
    this.isAdded = false;
    this.isLayer = false;
    if (value == "confirm") {
      this.closebutton.nativeElement.click();
      this.router.navigate(["/servedOrder"]);
    }
  }

  goHome() {
    this.router.navigate(["/home"]);
  }



  onIncrement(item): void {
    item.quantity++;
    this.addToCart(item);
  }

  onDecrement(item, i): void {
    item.quantity -= 1;
    if (item.quantity != 0) {
      this.addToCart(item);
    }
    if (item.quantity < 1) {
      item.quantity = 1;
      this.deleteItem(i);
    }
  }

  clickAction(value) {
    this.isCallWaiter = false;
    this.isLayer = false;
    if (value == "yes") {
      this.closebutton.nativeElement.click();
      this.appService.callWaiter().subscribe(
        (result) => {
          this.isLayer = false;
          this.router.navigate(["/call-waiter"]);
          console.log("api call");
        },
        (error) => {
           this.isLayer = false;
          console.log(error);
        }
      );
    }
  }

  callWaiter() {
    this.isCallWaiter = true;
    this.isLayer = true;
  }
}
